import React from "react";


function ErrorPages() {
    return (
        <div className="md:max-w-full w-full h-screen bg-black">
            <div className="py-[100px] text-center">
                <h2 className="capitalize text-8xl text-yellow-500">Site Down 💀</h2>
                <h3 className="capitalize ">page not found</h3>

            </div>
        </div>
    );
}

export default ErrorPages;
